import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";

const whatWeDo: IList[] = [
  {
    title: "Crisis Management",
    caption:
      "Don't let a crisis define you. Protect your reputation with our crisis management...",
    image: `${process.env.PUBLIC_URL + "/images/services/crisis1.png"}`,
    link: "/landing/services/1/Crisis_Management",
  },
  {
    title: "Reputation & Brand Boosting",
    caption:
      "Let LampHills help you build a powerful presence that resonates with your audience...",
    image: `${process.env.PUBLIC_URL + "/images/services/brand1.png"}`,
    link: "/landing/services/2/Reputation_Brand_Boosting",
  },
  {
    title: "Launch PR/Event Management",
    caption:
      "Leave the ordinary behind and host unforgettable launches and events that will... ",
    image: `${process.env.PUBLIC_URL + "/images/services/pr1.png"}`,
    link: "/landing/services/3/Launch_PR_Event_Management",
  },
  {
    title: "Media Relations",
    caption:
      "Cut through the noise and get your story to the right audience with LampHills' media relations... ",
    image: `${process.env.PUBLIC_URL + "/images/services/media1.png"}`,
    link: "/landing/services/4/Media_Relations",
  },
  {
    title: "Content Creation Package",
    caption:
      "Are you tired of bland content that gets ignored? Our content creation package is the solution for you... ",
    image: `${process.env.PUBLIC_URL + "/images/services/content1.png"}`,
    link: "/landing/services/5/Content_Creation_Package",
  },
  {
    title: "Press Releases and Speeches",
    caption:
      "Do you want powerful press releases and speeches that grab media attention and leave a memorable impression?",
    image: `${process.env.PUBLIC_URL + "/images/services/press1.png"}`,
    link: "/landing/services/6/Press_Releases_and_Speeches",
  },
];

export const ServiceSlide = () => {
  return (
    <>
      <Slide up>
        {/* Our Service Slide */}
        <div className="w3-white w3-col howTo w3-center">
          {/* Motions for Implement*/}
          <div className="w3-hide-small w3-hide-medium">
            <MotionCompGeneral
              showMoveLeft={true}
              showMoveRight={true}
              showRotateLeft={true}
              moveRightImg={`${
                process.env.PUBLIC_URL + "/images/homePage/img-2-1.png"
              }`}
            ></MotionCompGeneral>
          </div>

          <h2 className="myfont1">What We Do</h2>

          <p className="fontJostRegular w3-medium">
            We aim to help you create a positive image, manage crises with
            strategic expertise, and strengthen stakeholder relationships to
            maintain a strong reputation, <br /> visibility, credibility, and
            trust among your target audience.
          </p>

          <div>
            <h2 className="fontJostBold w3-large">
              At LampHills, our suite of services is designed to cater to every
              facet of your public relations needs.
              <br />   <br /> 
            </h2>
          </div>
        </div>
        {/* Our Service Slide */}

        {/*  Service Slide */}
        <div className="w3-white w3-col categoryOfWork w3-center ">
          {/* Motions for Category */}
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
          ></MotionCompGeneral>
          <div className="w3-content">
            <Swiper
              // install Swiper modules
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              navigation
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              spaceBetween={15}
              slidesPerView={3}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 600x
                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // when window width is >= 840px
                840: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {whatWeDo.map((item: IList, index: number) => (
                <SwiperSlide>
                  <div className="w3-col" style={{ padding: "15px" }}>
                    <div className="w3-col w3-padding w3-round-xlarge w3-card w3-center">
                      <div
                        className="w3-col"
                        style={{
                          marginTop: "40px",
                          marginBottom: "40px",
                        }}
                      >
                        <img
                          className="w3-margin-top w3-round-xlarge w3-margin-bottom"
                          src={item.image}
                          alt="image_"
                          style={{
                            maxWidth: "75%",
                            height: "150px",
                          }}
                        />
                      </div>

                      <h4 className="fontJostBold w3-large">{item.title}</h4>
                      <p className="fontJostRegular w3-medium">
                        {item.caption}
                      </p>
                      <p className="fontJostMedium w3-medium">
                        <Link to={item.link || "/"}> Know More </Link>
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* Service Slide */}
      </Slide>
    </>
  );
};

export default ServiceSlide;
